const en = {
  Platform: "Courant démocrate",
  Courant: "Courant Democrate",
  Quiz: "Quiz",
  Library: "Library",
  Pages: "Pages",
  Docs: "Docs",
  Dashboards: "Dashboards",
  Structures: "Structures",
  ListOfOffices: "All structures",
  ListOfUsers: "List of members",
  Article: "Article",
  CourantDemocrate: "Courant Democrate",
  Administration: "Administration",
  Users: "Members",
  Groups: "Groups",
  SearchHere: "Search here",
  MyProfile: "Profile",
  Setting: "Setting",
  Logout: "Logout",
  UserManagement: "User Management",
  GroupManagement: "Group Management",
  OfficeManagement: "Office Management",
  EventManagement: "Event Management",
  PostManagement: "Post Management",
  QuizManagement: "Quiz Management",
  ProjectManagement: "Project Management",
  Offices: "Offices",
  CombineOffices: "Combine Offices",
  CombineUsers: "Combine Users",
  MoreInfoAbout: "More info about",
  Username: "Username",
  FirstName: "First Name",
  LastName: "Last Name",
  OfficeName: "Office",
  OfficeType: "Type",
  OfficeParticipants: "Participants",
  OfficeActive: "Active",
  OfficeAction: "Action",
  Email: "Email",
  Gender: "Gender",
  Phone: "Phone",
  Birthday: "Birthday",
  Function: "Function",
  Action: "Action",
  ItemsPerPage: "Items per page",
  NewUser: "New User",
  EditUser: "Edit User",
  Save: "Save",
  Cancel: "Cancel",
  userForm: {
    login: {
      login: "Connexion",
      stayConnected: "Rester connecté",
      loginAction: "Se connecter",
      forgotPassword: "Mot de passe oublié",
    },
    register: {
      register: "Inscription",
      tos: "J'accepte les conditions d'utilisation",
      registerAction: "S'enregistrer",
    },
    data: {
      mail: "Addresse e-mail",
      password: "Mot de passe",
      passwordVerif: "Confirmation du mot de passe",
    },
    rules: {
      invalidMail: "L'addresse e-mail est invalide",
      emptyMail: "L'addresse e-mail est vide",
      emptyPassword: "Le mot de passe est requis",
      emptyPasswordVerif: "La confirmation du mot de passe est requise",
      passwordVerifDoesntMatch:
        "Le mot de passe diffère de la confirmation du mot de passe",
      emptyTOS: "Veuillez accepter les conditions d'utilisation",
    },
  },
  Home: {
    ForumMembers: "Forum Members",
  },
  BureauPolitique: "Bureau Politique",
  BureauExecutive: "Bureau Executive",
  ConseilNational: "Conseil National",
  BureauRegional: "Bureau Regional",
  BureauLocal: "Bureau Local",
  ProfileInformation: "Profile Information",
  FullName: "Full Name",
  Mobile: "Mobile",
  Location: "Location",
  Social: "",
  PlatformSettings: "Social",
  Account: "Account",
  EmailMeWhenNewEvent: "Email me when new event",
  EmailMeWhenSomeoneAnswersOnMyPost: "Email me when someone answers on my post",
  EmailMeWhenSomeoneMentionsMeInPost:
    "Email me when someone mentions me in post",
  EmailMeWhenSomeoneStartANewConversation:
    "Email me when someone start a new conversation",
  Conversations: "Conversations",
  Reply: "Reply",
  Projects: "Projects",
  Profile: "Profile",
  ProfileOverview: "Profile Overview",
  SwitchToVisible: "Switch to visible",
  SwitchToInVisible: "Switch to invisible",
  BasicInfo: "Basic Info",
  ImageProfile: "Image Profile",
  DescriptionProfile: "Description Profile",
  BirthDate: "",
  Male: "Male",
  Female: "Female",
  ConfirmationEmail: "Confirmation Email",
  YourLocation: "Your Location",
  PhoneNumber: "Phone Number",
  UpdateInformation: "Update Information",
  ChangePassword: "Change Password",
  CurrentPassword: "Current Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  OneSpecialCharacters: "One special characters",
  MinCharacters: "Min 6 characters",
  OneNumberAreRecommended: "One number (2 are recommended)",
  ChangeItOften: "Change it often",
  UpdatePassword: "Update Password",
  PasswordRequirements: "Password Requirements",
  PleaseFollowThisGuideForAStrongPassword:
    "Please follow this guide for a strong password:",
  Notifications: "Notifications",
  ChooseHowYouReceiveNotificationsTheseNotificationSettingsApplyToTheThingsYouAreWatching:
    "Choose how you receive notifications. These notification settings apply to the things you’re watching.",
  Activity: "Activity",
  Mentions: "Mentions",
  NotifyWhenAnotherUserMentionsYouInAComment:
    "Notify when another user mentions you in a comment",
  Comments: "Comments",
  NotifyWhenAnotherUserCommentsYourItem:
    "Notify when another user comments your item",
  Follows: "Follows",
  NotifyWhenAnotherUserFollowsYou: "Notify when another user follows you",
  LogInFromANewDevice: "Log in from a new device",
  Sessions: "Sessions",
  ThisIsAlListOfDevicesThatHaveLoggedIntoYourAccountRemoveThoseThatYouDoNotRecognize:
    "This is a list of devices that have logged into your account. Remove those that you do not recognize.",
  DeleteAccount: "Delete Account",
  OnceYouDeleteYourAccountThereIsNoGoingBackPleaseBeCertain:
    "Once you delete your account there is no going back. please Be certain.",
  Deactivate: "Deactivate",
  SeeOurDashboardOptions: "See our dashboard options",
  SidebarColors: "Sidebar Colors",
  SideNavigatorType: "Side navigator Type",
  ChooseBetweenDifferentSideNavigatorTypes:
    "Choose between 3 different side navigator types.",
  YouCanChangeTheSidenavTypeJustOnDesktopView:
    "You can change the sidenav type just on desktop view.",
  white: "white",
  dark: "dark",
  transparent: "transparent",
};

export default en;
