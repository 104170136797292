const ar = {
  Platform: "التيار الديمقراطي",
  Courant: "التيار الديمقراطي",
  Quiz: "اختبارات",
  Library: "مكتبة",
  Pages: "الصفحات",
  Docs: "معلومات",
  Dashboards: "المجموعة الرسمية",
  Home: "عام",
  Structures: "الهياكل",
  Structure: "الهياكل",
  ListOfOffices: "جميع الهياكل",
  ListOfPoliticOffices: "المكاتب السياسية",
  ListOfUsers: "قائمة المنخرطين",
  Article: "منشور",
  Administration: "إدارة",
  Users: "الأعضاء",
  Groups: "المجموعات",
  SearchHere: "ابحث هنا",
  Search: "ابحث",
  MyProfile: "الصفحة الشخصية",
  Setting: "تعديل",
  Logout: "خروج",
  UserManagement: "إدارة المستخدمين",
  GroupManagement: "إدارة المجموعات",
  OfficeManagement: "إدارة المكاتب",
  EventManagement: "إدارة الاجتماعات",
  PostManagement: "إدارة المناشير",
  QuizManagement: "إدارة الاختبارات",
  ProjectManagement: "إدارة المشاريع",
  Offices: "المكاتب",
  Office: "المكتب",
  CombineOffices: "دمج المكاتب",
  CombineUsers: "دمج المنخرطين",
  MoreInfoAbout: "مزيد من المعلومات حول",
  Username: "التعريف",
  FirstName: "الاسم",
  LastName: "اللقب",
  Email: "البريد الاكتروني",
  Gender: "الجنس",
  Phone: "الهاتف",
  Birthday: "تاريخ الميلاد",
  Function: "المهنة",
  Action: "تعديل",
  OfficeName: "المكتب",
  OfficeType: "مكتب جهوي/محلي",
  OfficeParticipants: "عدد المنخرطين",
  PoliticOfficeParticipants: "عدد الأعضاء",
  OfficeActive: "موجود",
  OfficeAction: "تعديل",
  ItemsPerPage: "عدد العناصر في كل صفحة",
  user: "المنخرطين",
  NewUser: "منخرط جديد",
  EditUser: "تحديث بيانات المنخرط",
  Save: "تسجيل",
  Cancel: "إلغاء",
  Close: "غلق",
  userForm: {
    login: {
      login: "Connexion",
      stayConnected: "Rester connecté",
      loginAction: "Se connecter",
      forgotPassword: "Mot de passe oublié",
    },
    register: {
      register: "Inscription",
      tos: "J'accepte les conditions d'utilisation",
      registerAction: "S'enregistrer",
    },
    data: {
      mail: "Addresse e-mail",
      password: "Mot de passe",
      passwordVerif: "Confirmation du mot de passe",
    },
    rules: {
      invalidMail: "L'addresse e-mail est invalide",
      emptyMail: "L'addresse e-mail est vide",
      emptyPassword: "Le mot de passe est requis",
      emptyPasswordVerif: "La confirmation du mot de passe est requise",
      passwordVerifDoesntMatch:
        "Le mot de passe diffère de la confirmation du mot de passe",
      emptyTOS: "Veuillez accepter les conditions d'utilisation",
    },
  },
  BureauPolitique: "المكتب السياسي",
  BureauExecutive: "المكتب التنفيذي",
  ConseilNational: "المجلس الوطني",
  BureauRegional: "المكتب الجهوي",
  BureauLocal: "المكتب المحلي",
  ProfileInformation: "معلومات شخصية",
  FullName: "الاسم الكامل",
  Mobile: "الجوال",
  Location: "العنوان",
  Social: "الحسابات",
  PlatformSettings: "إعدادات النظام الأساسي",
  Account: "الحساب الشخصي",
  EmailMeWhenNewEvent: "أرسل لي بريدًا إلكترونيًا عند حدوث حدث جديد",
  EmailMeWhenSomeoneAnswersOnMyPost:
    "أرسل لي بريدًا إلكترونيًا عندما يجيب شخص ما على رسالتي",
  EmailMeWhenSomeoneMentionsMeInPost:
    "أرسل لي بريدًا إلكترونيًا عندما يذكرني شخص ما في البريد",
  EmailMeWhenSomeoneStartANewConversation:
    "أرسل لي بريدًا إلكترونيًا عندما يبدأ شخص ما محادثة جديدة",
  Conversations: "المحادثات",
  Reply: "رد",
  Projects: "المشاريع",
  Profile: "الصفحة الشخصية",
  ProfileOverview: "معلومات",
  SwitchToVisible: "ظهور",
  SwitchToInVisible: "عدم ظهور",
  BasicInfo: "معلومات أساسية",
  ImageProfile: "الصورة",
  BirthDate: "تاريخ الميلاد",
  DescriptionProfile: "تقديم لشخصي",
  Description: "تعريف",
  Name: "الإسم",
  Actions: "الإجراءات",
  Male: "ذكر",
  Female: "أنثى",
  ConfirmationEmail: "تأكيد البريد الاكتروني",
  YourLocation: "العنوان",
  PhoneNumber: "الهاتف",
  UpdateInformation: "تحديث المعطيات",
  ChangePassword: "تغيير كلمة المرور",
  CurrentPassword: "كلمة المرور الحالية",
  NewPassword: "كلمة مرور جديدة",
  ConfirmNewPassword: "تأكيد كلمة المرور الجديدة",
  OneSpecialCharacters: "شخصيات خاصة واحدة",
  MinCharacters: "الحد الادني 6 أحرف",
  OneNumberAreRecommended: "رقم (2 موصى به)",
  ChangeItOften: "قم بتغييره كثيرًا",
  UpdatePassword: "تحديث كلمة المرور",
  PasswordRequirements: "متطلبات كلمة المرور",
  PleaseFollowThisGuideForAStrongPassword:
    "الرجاء اتباع هذا الدليل للحصول على كلمة مرور قوية:",
  Notifications: "الإخطارات",
  ChooseHowYouReceiveNotificationsTheseNotificationSettingsApplyToTheThingsYouAreWatching:
    "اختر كيفية تلقي الإخطارات. تنطبق إعدادات الإشعارات هذه على الأشياء التي تشاهدها.",
  Activity: "النشاط",
  Mentions: "يذكر",
  NotifyWhenAnotherUserMentionsYouInAComment:
    "إعلام عندما يشير إليك مستخدم آخر في تعليق",
  Comments: "التعليقات",
  NotifyWhenAnotherUserCommentsYourItem:
    "إعلام عندما يقوم مستخدم آخر بالتعليق على العنصر الخاص بك",
  Follows: "المتابعين",
  NotifyWhenAnotherUserFollowsYou: "علم عندما يتابعك مستخدم آخر",
  LogInFromANewDevice: "سجل الدخول من جهاز جديد",
  Sessions: "مستخدم",
  ThisIsAlListOfDevicesThatHaveLoggedIntoYourAccountRemoveThoseThatYouDoNotRecognize:
    "هذه قائمة بالأجهزة التي سجلت الدخول إلى حسابك. قم بإزالة تلك التي لا تعرفها.",
  DeleteAccount: "إلغاء الحساب",
  OnceYouDeleteYourAccountThereIsNoGoingBackPleaseBeCertain:
    "بمجرد حذف حسابك ، لن يكون هناك عودة. من فضلك كن متأكدا.",
  Deactivate: "إستراحة",
  SeeOurDashboardOptions: "انظر خيارات لوحة القيادة لدينا",
  SidebarColors: "ألوان الشريط الجانبي",
  SideNavigatorType: "نوع التنقل الجانبي",
  ChooseBetweenDifferentSideNavigatorTypes:
    "اختر من بين 3 أنواع مختلفة للتنقل الجانبية.",
  YouCanChangeTheSidenavTypeJustOnDesktopView:
    "يمكنك تغيير نوع التنقل الجانبي فقط في عرض سطح المكتب.",
  white: "أبيض",
  dark: "مظلم",
  transparent: "شفاف",
  navbarFixed: "شريط التنقل ثابت",
  January: "جانفي",
  February: "فيفري",
  March: "مارس",
  April: "افريل",
  May: "ماي",
  June: "جوان",
  July: "جويلية",
  August: "أوت",
  September: "سبتمبر",
  October: "أكتوبر",
  November: "نوفمبر",
  December: "ديسمبر",
  ListOfParameters: "قائمة المعلمات",
  Categories: "الاقسام",
  HighScores: "أفضل الدرجات",
  ChoiceLevel: "مستوى الاختبار",
  Level: "مستوى",
  AddPost: "إظافة منشور",
  postTitle: "النص",
  postDescription: "تعبير",
  NewPost: "منشور جديد",
  likes: "إعجاب",
  and: "و",
  more: "آخرون",
  WriteYourComment: "اكتب تعليقك هنا",
  Members: "الأعضاء",
  Position: "المسؤولية",
  Since: "منذ",
  PoliticOffices: "المكتب السياسي",
  politicOffices: "المكتب السياسي",
  PoliticOfficeManagement: "إدرة المكاتب السياسية",
  ExecutifOffices: "المكتب التنفيذي",
  NationalCouncil: "المجلس الوطني",
  Comittee: "اللجان",
  NewOffice: "مكتب جديد",
  EditOffice: "تحديث المكتب",
  Rapports: "محاضر الجلسات",
  Statements: "البيانات",
  Events: "الأنشطة",
  Reunions: "الاجتماعات",
  PoliticOfficeName: "إسم المكتب",
  Quitters: "المستقيلين",
  NewMembers: "الملتحقين الجدد",
  FinancialRapports: "التقارير المالية",
  NumberParticipants: "عدد الاعضاء",
  NumberEvents: "عدد الاجتماعات",
  NumberMeetings: "عدد الانشطة",
  AddUser: "إضافة أعضاء للمجموعة",
  AddRapport: "إضافة محضر جلسة",
  SearchUsersOffice: "ابحث عن منخرطين...",
  YourProfileProgress: " حسابك غير محدث ",
  CompleteYourProfile: "يرجى ملء البيانات الشخصية الخاصة بك",
  wlcProfil:"مرحباً",
  wlcProfilDesc1:"مرحبا بكم في تطبيقة التيار الديمقراطي،",
  wlcProfilDesc2:"التطبيقة هي خطوة لارساء ثقافة رقمية و بناء اسس إدارة عصرية و لهذا فنحن نعول عليكم لانجاح المشروع.",
  bureauNationaux:"المكاتب الوطنية",
  bureauRegionaux:"المكاتب الجهوية",
  membreTotal:"عدد الأعضاء",
  membre:"عضو",
  CompleteYourProfile: "أكمل تسجيل معطياتك",
  AddStatement: "إضافة بيان",
  AddEvent: "إضافة نشاط",
  position: "المسؤولية",
  Meetings: "إجتماعات",
  Dashboard: "المجموعة الرسمية",
  Title: "عنوان",
  Edit: "تعديل",
};

export default ar;
